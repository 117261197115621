<script setup lang="ts">

const props = withDefaults(defineProps<{
  variant?: 'primary' | 'secondary' | 'neutral' | 'transparent',
  size?: 'small' | 'medium' | 'large'
  outlined?: boolean,
  disabled?: boolean,
  to?: string,
  type?: 'button' | 'submit',
}>(), {
  variant: 'primary',
  size: 'medium',
  type: 'button',
})

const emit = defineEmits(['click'])

const style = computed(() => {
  const classList: string[] = []

  if (props.outlined) {
    classList.push('border-2')

    if (props.variant === 'primary') {
      classList.push('border-primary hover:bg-primary/10 text-primary')
    } else if (props.variant === 'secondary') {
      classList.push(
        'border-secondary hover:bg-primary/10 text-secondary',
      )
    } else if (props.variant === 'neutral') {
      classList.push(
        'border-neutral hover:bg-primary/10 text-secondary',
      )
    } else {
      classList.push(
        'text-slate-400 hover:text-slate-400/60',
      )
    }
  } else if (props.variant === 'primary') {
    classList.push('bg-primary hover:bg-primary/90 text-white')
  } else if (props.variant === 'secondary') {
    classList.push('bg-secondary hover:bg-primary/90 text-white')
  } else if (props.variant === 'neutral') {
    classList.push('bg-neutral hover:bg-neutral-200/90 text-white')
  } else {
    classList.push('text-slate-400 hover:text-slate-400/60')
  }

  if (props.size === 'small') {
    classList.push('w-6 h-6 text-xs')
  } else if (props.size === 'medium') {
    classList.push('w-8 h-8 text-sm')
  } else {
    classList.push('w-12 h-12 text-md')
  }

  if (props.disabled) {
    classList.push('opacity-50 cursor-not-allowed focus:outline-none')
  }

  return classList.join(' ')
})

const onClick = () => {
  if (props.disabled) {
    return
  }

  if (props.to) {
    navigateTo(props.to)
  }

  emit('click')
}
</script>

<template>
  <button class="flex items-center justify-center focus:outline-none focus:ring-none rounded-full" :class="style"
    :type="type" @click="onClick">
    <slot />
  </button>
</template>
